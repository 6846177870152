import React from "react"
import ModalVideo from "react-modal-video"
import ProgressBar from "react-scroll-progress-bar"
import ReactFullpage from "@fullpage/react-fullpage"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aibot_logo from "../images/aibot-logo.png"
import aichef from "../images/aichef.png"
import aisolutions_logo from "../images/aisolutions-logo.png"
import default_aibot from "../images/default-aibot.png"
import icon_section_2 from "../images/icon-section-2.png"
import icon_section_3 from "../images/icon-section-3.png"
import icon_section_4 from "../images/icon-section-4.png"
import icon_section_6 from "../images/icon-section-6.png"
import icon_section_7_1 from "../images/icon-section-7-1.png"
import icon_section_7_2 from "../images/icon-section-7-2.png"
import icon_section_7_3 from "../images/icon-section-7-3.png"
import icon_section_8 from "../images/icon-section-8.png"
import icon_section_9_1 from "../images/icon-section-9-1.png"
import icon_section_9_2 from "../images/icon-section-9-2.png"
import icon_section_9_3 from "../images/icon-section-9-3.png"
import icon_section_9_4 from "../images/icon-section-9-4.png"
import item_section_1 from "../images/item-section-1.png"
import item_section_2 from "../images/item-section-2.png"
import item_section_3 from "../images/item-section-3.png"
import item_section_4 from "../images/item-section-4.png"
import item_section_6_1 from "../images/item-section-6-1.png"
import item_section_6_2 from "../images/item-section-6-2.png"
import item_section_8 from "../images/item-section-8.png"
import item_section_10 from "../images/item-section-10.png"

export default class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <Layout>
        <SEO title="Inicio" />
        <ProgressBar height="2px" bgcolor="#00f3a7" />
        <ReactFullpage
          autoScrolling={false}
          lockAnchors={true}
          fixedElements={[".navbar", ".menu"]}
          anchors={[
            "inicio",
            "atencionAlCliente",
            "marketingEcommerce",
            "comunicacionInterna",
            "gestionar",
            "bigDataLeads",
            "bot",
            "resultados",
            "lanzamiento",
            "evitaFilas",
          ]}
          menu="#menuPrimary"
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <section
                  id="section1"
                  className="section hero fp-auto-height section-1 is-fullheight is-primary"
                >
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-flex-end">
                        <div className="column is-one-third">
                          <div className="content">
                            <img src={aibot_logo} alt="aibot" />
                            <div className="hero-section">
                              <h1 className="title is-uppercase is-size-3">
                                IMAGINA UN ASISTENTE TRABAJANDO 24/7
                              </h1>
                              <p className="is-size-5">
                                Es un asistente capaz de automatizar tareas y
                                conversar con personas gracias al desarrollo de
                                inteligencia artificial.
                              </p>
                              <div className="columns ">
                                <div className="column">
                                  <a
                                    href="/solicitud-de-demo/"
                                    className="button is-large is-primary has-text-weight-bold is-uppercase button-demo"
                                  >
                                    Solicitar Demo
                                  </a>
                                </div>
                                <div className="column">
                                  <a
                                    onClick={this.openModal}
                                    className="button is-large is-primary has-text-weight-bold is-uppercase button-video"
                                  >
                                    ¡Ver Vídeo!
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column is-half">
                          <img src={item_section_1} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section2"
                  className="section hero fp-auto-height section-2 is-fullheight is-primary"
                >
                  <div className="hero-head">
                    <div className="container">
                      <div className="columns is-centered has-text-centered">
                        <div className="column">
                          <h1 className="title is-uppercase is-inline-block is-size-3">
                            ¿Por qué tu empresa Necesita AIBOT?
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-vcentered">
                        <div className="column is-one-third is-offset-2">
                          <img src={item_section_2} />
                        </div>
                        <div className="column">
                          <img className="icon is-large" src={icon_section_2} />
                          <h2 className="subtitle is-size-4 has-text-weight-bold">
                            ATENCIÓN AL CLIENTE
                          </h2>
                          <div className="content is-size-5">
                            <ul>
                              <li>Disponible 24 horas / 365 días al año.</li>
                              <li>
                                Perfecto para asistir a un comunity manager.
                              </li>
                              <li>
                                Servicio pre-venta y seguimiento postventa.
                              </li>
                              <li>Crea confianza a los clientes.</li>
                              <li>Siempre disponible a quejas o reclamos.</li>
                              <li>
                                Tiene la capacidad de resolver problemas
                                técnicos.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section3"
                  className="section hero fp-auto-height section-3 is-fullheight"
                >
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-vcentered">
                        <div className="column is-offset-2 is-one-third">
                          <img className="icon is-large" src={icon_section_3} />
                          <h2 className="subtitle is-size-4 has-text-weight-bold">
                            MARKETING / ECOMMERCE
                          </h2>
                          <div className="content is-size-5">
                            <ul>
                              <li>Crea leads y suscriptores.</li>
                              <li>Hace reservas, cotizaciones y citas.</li>
                              <li>Recomienda productos.</li>
                              <li>Ayuda en el proceso de compra.</li>
                              <li>Resuelve dudas al instante.</li>
                              <li>Cierra la venta.</li>
                              <li>Envía promociones, cupones, catálogos.</li>
                            </ul>
                          </div>
                        </div>
                        <div className="column is-one-third">
                          <img src={item_section_3} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section4"
                  className="section hero fp-auto-height section-4 is-fullheight"
                >
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns">
                        <div className="column is-half is-offset-2">
                          <img className="icon is-large" src={icon_section_4} />
                          <h2 className="subtitle is-size-4 has-text-weight-bold">
                            COMUNICACIÓN INTERNA
                          </h2>
                          <div className="content is-size-5">
                            <ul>
                              <li>Ahorro de tiempo en consultas.</li>
                              <li>
                                Envío de información relevante a cada
                                departamento automáticamente.
                              </li>
                              <li>
                                Consultas automáticas a protocolos de la empresa
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="columns is-centered">
                        <div className="column is-three-quarters has-text-centered">
                          <img src={item_section_4} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section5"
                  className="section hero fp-auto-height section-5 is-fullheight"
                >
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-vcentered">
                        <div className="column is-primary is-two-fifths has-background-primary">
                          <h1 className="title is-size-2 has-text-white">
                            AIBOT SE ENCARGA DE GESTIONAR LA INFORMACIÓN, ¡TU
                            SOLO LA APRUEBAS!
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section6"
                  className="section hero fp-auto-height section-6 is-fullheight"
                >
                  <div className="hero-head">
                    <div className="container">
                      <div className="columns is-centered has-text-centered">
                        <div className="column">
                          <h1 className="title is-uppercase is-inline-block is-size-3 has-text-primary">
                            REPORTE
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns">
                        <div className="column is-offset-1 is-one-third">
                          <img className="icon is-large" src={icon_section_6} />
                          <h2 className="subtitle is-size-4 has-text-weight-bold">
                            USUARIOS ACTIVOS
                          </h2>
                          <div className="content content-special is-size-5">
                            <div className="columns">
                              <div className="column">
                                <ul>
                                  <li>Perfil:</li>
                                  <li>Edades:</li>
                                  <li>Comportamiento:</li>
                                  <li></li>
                                  <li></li>
                                  <li>Intereses:</li>
                                  <li></li>
                                  <li></li>
                                  <li>Links:</li>
                                </ul>
                              </div>
                              <div className="column">
                                <img src={item_section_6_1} />
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column has-text-centered">
                                <img src={item_section_6_2} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column is-offset-1 is-one-third column-align">
                          <h2 className="subtitle is-size-4 has-text-weight-bold">
                            BIG DATA / LEADS
                          </h2>
                          <div className="content is-size-5">
                            <ul>
                              <li>Crea estrategias basadas en datos reales.</li>
                              <li>Crea una base de datos completa.</li>
                              <li>
                                Estudio del comportamiento y gustos del cliente.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section7"
                  className="section hero fp-auto-height section-7 is-fullheight"
                >
                  <div className="hero-head">
                    <div className="container">
                      <div className="columns is-centered has-text-centered">
                        <div className="column">
                          <h1 className="title is-uppercase has-text-primary">
                            BOT = ECOMMERCE = AUMENTO EN INGRESOS
                          </h1>
                          <h2 className="subtitle is-uppercase has-text-primary">
                            CONSIGUE MÁS VENTAS, OBTÉN MÁS RESERVAS, MÁS CITAS,
                            MÁS FANS, MÁS CLIENTES FIELES
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-centered">
                        <div className="column is-one-third has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_7_1}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            BASE DE DATOS
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Recolecta información relevante de tus clientes,
                              como por ejemplo, preferencias, gustos, patrones
                              de compra, emails, telefono, entre otros. Sabrás
                              el comportamiento del cliente durante la
                              conversación.
                            </p>
                          </div>
                        </div>
                        <div className="column is-one-third is-offset-1 has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_7_2}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            BROADCAST
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Gracias a los datos recolectados por nuestro
                              machine learning podremos enviar información
                              relevante a tus clientes dependiendo de sus gustos
                              y preferencias. Podrás hacer anuncios sobre nueva
                              mercadería, ofertas y mucho más.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns is-centered">
                        <div className="column is-one-third has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_7_3}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            SEGUIMIENTO
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Recibe feedback de tus clientes post-venta.
                              ¿Quieres saber por que no se completó la compra, o
                              la cita? ¿Por qué no terminaron de llenar el
                              formulario? Todas estas respuestas las podrás
                              obtener con nuestro bot.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section8"
                  className="section hero fp-auto-height section-8 is-fullheight"
                >
                  <div className="hero-head">
                    <div className="container">
                      <div className="columns is-centered has-text-centered">
                        <div className="column">
                          <h1 className="title is-uppercase is-inline-block is-size-3">
                            RESULTADOS EN NÚMEROS
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns">
                        <div className="column is-half is-offset-5">
                          <div className="content is-size-5">
                            <ul>
                              <li>
                                Costo por conversión 40% más barato que otro
                                tipo de publicidad.
                              </li>
                              <li>
                                3 veces más retorno por cada $1 invertido en
                                publicidad.
                              </li>
                              <li>
                                Reduce el tiempo promedio de espera de los
                                clientes en un 80%, a pesar de un aumento del
                                40% en las conversaciones totales.
                              </li>
                              <li>
                                Consigue diariamente 5% más clientes potenciales
                                orgánicamente.
                              </li>
                              <li>
                                Optimiza tus recursos, el bot decide que
                                usuarios necesitan atención 1 a 1 con un agente
                                humano.
                              </li>
                              <li>
                                2 veces más ingresos con nuestro sistema de
                                broadcast.
                              </li>
                              <li>
                                100% efectividad recopilando data real y
                                relevante para tu empresa.
                              </li>
                              <li>
                                Actualización de base de datos en tiempo real
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-centered has-text-centered">
                          <div className="content">
                            <img className="is-large" src={icon_section_8} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section9"
                  className="section hero fp-auto-height section-9 is-fullheight"
                >
                  <div className="hero-head">
                    <div className="container">
                      <div className="columns is-centered has-text-centered">
                        <div className="column">
                          <h1 className="title is-uppercase is-inline-block is-size-3">
                            LANZAMIENTO
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-centered">
                        <div className="column is-one-third is-centered has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_9_1}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            PREPARACIÓN
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Recopilamos toda la información necesaria para
                              crear la inteligencia detrás de tú AIBOT.
                            </p>
                          </div>
                        </div>
                        <div className="column is-one-third is-centered has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_9_2}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            CREACIÓN
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Nuestros desarrolladores adaptan, integran y
                              preparan tu AIBOT.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns is-centered">
                        <div className="column is-one-third is-centered has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_9_3}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            LANZAMIENTO BETA
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Lanzamos la primera versión para que nuestro
                              cliente lo pruebe. En esta fase se hacen cambios
                              necesarios.
                            </p>
                          </div>
                        </div>
                        <div className="column is-one-third is-centered has-text-centered">
                          <img
                            className="icon is-large"
                            src={icon_section_9_4}
                          />
                          <h2 className="subtitle is-size-4 has-text-weight-bold has-text-primary">
                            LANZAMIENTO FINAL
                          </h2>
                          <div className="content is-size-5 has-text-primary">
                            <p>
                              Lanzamos tu AIBOT al público. Los insights y
                              feedback recibidos los utilizamos para los
                              mantenimientos mensuales.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="section10"
                  className="section hero fp-auto-height section-10 is-fullheight"
                >
                  <div className="hero-body">
                    <div className="container">
                      <div className="columns is-vcentered">
                        <div className="column is-half">
                          <img src={item_section_10} />
                        </div>
                        <div className="column is-half">
                          <div className="columns">
                            <div className="column column-top">
                              <h1 className="title has-text-white is-size-3">
                                SIEMPRE TIENES EL CONTROL
                              </h1>
                              <div className="content is-size-5 has-text-white">
                                <p>
                                  Toda la información que obtiene tu asistente
                                  es importante para convertir tu negocio en una
                                  super estrella. El perfecto complemento para
                                  un community manager.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-offset-4">
                              <h1 className="title has-text-white is-size-special">
                                EVITA FILAS
                              </h1>
                              <div className="content is-size-3 has-text-white">
                                <p>AIBot reserva por ti.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hero-foot">
                    <div className="container has-text-centered">
                      <img src={aibot_logo} />
                    </div>
                  </div>
                </section>
                <aside
                  id="menuPrimary"
                  className="menu is-hidden-touch	has-text-right is-size-7"
                >
                  <ul className="menu-list">
                    <li data-menuanchor="inicio" className="active">
                      <a href="#" onClick={() => fullpageApi.moveTo("inicio")}>
                        INICIO
                      </a>
                    </li>
                    <li data-menuanchor="atencionAlCliente">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("atencionAlCliente")}
                      >
                        ATENCIÓN AL CLIENTE
                      </a>
                    </li>
                    <li data-menuanchor="marketingEcommerce">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("marketingEcommerce")}
                      >
                        MARKETING / ECOMMERCE
                      </a>
                    </li>
                    <li data-menuanchor="comunicacionInterna">
                      <a
                        href="#"
                        onClick={() =>
                          fullpageApi.moveTo("comunicacionInterna")
                        }
                      >
                        COMUNICACIÓN INTERNA
                      </a>
                    </li>
                    <li data-menuanchor="gestionar" className="is-hidden">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("gestionar")}
                      >
                        GESTIONAR
                      </a>
                    </li>
                    <li data-menuanchor="bigDataLeads">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("bigDataLeads")}
                      >
                        BIG DATA / LEADS
                      </a>
                    </li>
                    <li data-menuanchor="bot">
                      <a href="#" onClick={() => fullpageApi.moveTo("bot")}>
                        BOT
                      </a>
                    </li>
                    <li data-menuanchor="resultados">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("resultados")}
                      >
                        RESULTADOS
                      </a>
                    </li>
                    <li data-menuanchor="lanzamiento">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("lanzamiento")}
                      >
                        LANZAMIENTO
                      </a>
                    </li>
                    <li data-menuanchor="evitaFilas" className="is-hidden">
                      <a
                        href="#"
                        onClick={() => fullpageApi.moveTo("evitaFilas")}
                      >
                        EVITA FILAS
                      </a>
                    </li>
                  </ul>
                </aside>
              </ReactFullpage.Wrapper>
            )
          }}
        />
        <ModalVideo
          channel="vimeo"
          isOpen={this.state.isOpen}
          videoId="343290724"
          onClose={() => this.setState({ isOpen: false })}
        />
      </Layout>
    )
  }
}
